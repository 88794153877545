'use client'

import { RiMenu2Line } from '@remixicon/react'
import { Button, Drawer, Grid } from 'antd'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useDrawerState from '@/hooks/context/useDrawerState'
import useThemeState from '@/hooks/context/useThemeState'
import useTourState from '@/hooks/context/useTourState'
import useAuth from '@/hooks/useAuth'

import { colors } from '@/branding-config'
import { generateUUID } from '@/utils'

import ChatSidebarContent from './Sidebar/ChatSidebarContent'

import { Conversation } from '@/types/chatbot'

const { useBreakpoint } = Grid

interface ChatLayoutProps extends PropsWithChildren {
  conversations: Conversation[]
  mutateConversations: () => void
  setConversationsSize: React.Dispatch<React.SetStateAction<number>>
  noMoreData?: boolean
  adminChatbot?: boolean
}

const ChatLayout: React.FC<ChatLayoutProps> = ({
  conversations,
  mutateConversations,
  setConversationsSize,
  noMoreData,
  adminChatbot,
  children,
}) => {
  const { theme } = useThemeState()
  const screens = useBreakpoint()
  const [searchParams] = useSearchParams()
  const [firstLoad, setFirstLoad] = useState(true)
  const { open, setSelectedConversation, selectedConversation, show } =
    useDrawerState()
  const { user } = useAuth()
  const { t } = useTranslation()
  const { userTourRef4 } = useTourState()

  useEffect(() => {
    if (searchParams && firstLoad) {
      setSelectedConversation(searchParams[1])
      setFirstLoad(false)
    }
  }, [searchParams])

  useEffect(() => {
    if (!selectedConversation) {
      setSelectedConversation(generateUUID())
    }
  }, [])

  if (!user) {
    return (
      <div className='flex h-screen flex-col'>
        <div className='absolute z-10 flex w-full items-center justify-end p-3'>
          <div className='flex gap-2'>
            <Link href='/login'>
              <Button>{t('sign-in')}</Button>
            </Link>
            {/* <Link href='/signup'>
              <Button type='primary'>{t('sign-up')}</Button>
            </Link> */}
          </div>
        </div>
        {children}
      </div>
    )
  }

  return (
    <div className='flex w-full'>
      {screens.md ? (
        <div className='w-[500px] bg-surface text-on-surface dark:bg-dark-surface dark:text-dark-on-surface'>
          <ChatSidebarContent
            conversations={conversations}
            mutateConversations={mutateConversations}
            setConversationsSize={setConversationsSize}
            noMoreData={noMoreData}
            adminChatbot={adminChatbot}
          />
        </div>
      ) : (
        <>
          <Drawer
            placement='left'
            closable={false}
            onClose={close}
            open={open}
            styles={{
              body: { backgroundColor: colors[theme].surface, padding: 0 },
            }}
          >
            <ChatSidebarContent
              conversations={conversations}
              mutateConversations={mutateConversations}
              setConversationsSize={setConversationsSize}
              noMoreData={noMoreData}
              adminChatbot={adminChatbot}
              isDrawer
            />
          </Drawer>
          <Button
            ref={userTourRef4}
            className='!absolute top-0 z-10 m-2'
            icon={<RiMenu2Line className='size-5' />}
            onClick={show}
          />
        </>
      )}
      <div className='w-full'>{children}</div>
    </div>
  )
}

export default ChatLayout
